.payment-history {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-left: 40px;

  h1 {
    font-size: 20px;
    font-weight: bold;
  }
}
.ph-table {
  margin-top: 10px;
  width: 960px;
  border-radius: 2px;
  .ph-table-legend {
    display: flex;
    align-items: center;
    width: 960px;
    height: 30px;
    border-bottom: 2px solid #333;
    .legend {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:last-child {
        border-right: none;
      }
    }
  }
  .date {
    width: 300px;
  }
  .amount {
    width: 200px;
  }
  .status {
    width: 200px;
  }
  .processed-at {
    width: 260px;
  }
  .ph-table-item {
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid #ccc;
    &.no-item {
      justify-content: center;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
    }
    .status-content {
      font-size: 12px;
      margin-right: 4px;
    }
    .paid {
      width: 30px;
      height: 20px;
      border-radius: 4px;
      background-color: $pink;
      color: #fff;
      font-weight: bold;
    }
    .pending {
      width: 50px;
      height: 20px;
      border-radius: 4px;
      background-color: $skyblue;
      color: #fff;
      font-weight: bold;
    }
    .canceled {
      width: 50px;
      height: 20px;
      border-radius: 4px;
      background-color: $purple;
      color: #fff;
      font-weight: bold;
    }
    .paid-at.cancel {
      font-size: 16px;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        font-weight: bold;
      }
    }
  }
}

.ph-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.3;
}
.ph-popup-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.ph-popup-container {
  width: 400px;
  height: 256px;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  position: relative;
  padding: 60px 24px;
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .btns-wrapper {
    margin-top: 30px;
    .submit {
      margin-left: auto;
      margin-right: auto;
      width: 320px;
      background-color: $pink;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      padding: 8px;
      text-align: center;
      cursor: pointer;
      border-radius: 6px;
      &:hover {
        background-color: #c7747f;
      }
      &.disabled {
        background-color: #666;
        cursor: not-allowed;
      }
    }
    .cancel {
      margin-top: 12px;
      margin-left: auto;
      margin-right: auto;
      width: 320px;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      cursor: pointer;
      padding: 8px;
      text-align: center;
      border-radius: 6px;
      background-color: #bebebe;
      &:hover {
        background-color: #aaa;
      }
    }
  }
}
