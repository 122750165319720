.popup {
  position: absolute;
  top: 200px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 1001;
  background-color: #ebebeb;
  border: 1px solid #bbb;
  border-radius: 3px;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
}

.popup-wrapper {
  position: relative;
  height: 300px;
  width: 700px;
  padding-top: 60px;
}

.popup__input-wrapper {
  width: 100%;
  margin-bottom: 24px;
  .information {
    font-size: 12px;
    color: $pink;
    margin-bottom: 20px;
    margin-left: 150px;
  }
}

.popup__input-label {
  width: 400px;
  height: 18px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-bottom: 12px;
}

.popup__input-label label {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.popup__input .error {
  color: $pink;
  font-size: 12px;
  margin-left: 150px;
  margin-top: 8px;
}

.popup__input input {
  margin-left: 150px;
  width: 400px;
  height: 36px;
  font-size: 16px;
  border: 1px solid #000;
  padding-left: 10px;
}

.popup__submit-btn {
  width: 400px;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  background-color: #333;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #444;
  }
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.popup.password-change {
  width: 700px;
  height: 400px;
  .authentication-popup__input-wrapper {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    label {
      display: block;
    }
  }
  .close-popup {
    position: absolute;
    top: 10px;
    right: 30px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.popup.password-change .popup-wrapper {
  padding-top: 40px;
}
