.comment__list {
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  h6 {
    font-size: 18px;
    font-weight: bold;
  }
}

.comment__items-wrapper {
  margin-top: 16px;
  width: 100%;
  position: relative;
}

.comment__item {
  display: flex;
  align-items: center;
  height: 44px;
  position: relative;
  border-bottom: 1px solid #f1f1f1;
  .commenter-image {
    width: 24px;
    height: 24px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .commenter-name {
    margin-left: 12px;
    width: 120px;
  }
  .content {
    width: calc(100% - 380px);
    text-align: center;
  }
  .created-at {
    width: 160px;
    font-size: 12px;
  }
  .likes-count {
    width: 64px;
    text-align: center;
  }

  .label__created-at {
    width: 160px;
  }
  .label__commenter {
    width: 156px;
  }
  .label__content {
    width: calc(100% - 380px);
  }
  .label__likes-count {
    width: 64px;
  }
  &.legend {
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    text-align: center;
  }
  &.best {
    .best-icon {
      position: absolute;
      top: 0;
      left: 0;
      img {
        width: 20px;
        height: 11px;
      }
    }
  }
}
