.analytics {
  width: calc(100% - 208px);
  height: 100%;
  padding-top: 30px;
  padding-left: 40px;

  h1 {
    font-size: 20px;
    font-weight: bold;
  }
}
.analytics__row {
  margin-top: 20px;
  padding-top: 12px;
  padding-bottom: 60px;
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .content {
    margin-top: 8px;
  }
}

.analytics__list-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  div {
    height: 32px;
  }
}

.analytics__list-item.legend {
  font-weight: bold;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
}

.analytics__list-item-title {
  width: 40%;
  display: flex;
  align-items: center;
}

.analytics__list-item-content {
  width: 60%;
  display: flex;
  align-items: center;
}
