.settlements {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-left: 40px;

  h1 {
    font-size: 20px;
    font-weight: bold;
  }
}
.settlements-table {
  margin-top: 20px;
  padding-top: 12px;
  padding-bottom: 60px;
}

.settlements__navbar-list {
  width: calc(100% - 208px);
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
}
.settlements__navbar-tab {
  width: 50%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  &.selected {
    border-bottom: 3px solid #333;
    font-weight: bold;
    background-color: #eee;
  }
  .btn__settlements-navbar {
    width: 50%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      font-weight: bold;
    }
  }
}
