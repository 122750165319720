.frame {
  width: 100%;
  min-width: 1024px;
  height: 100%;
  position: relative;
}

.wrapper {
  width: 100%;
  height: calc(100% - 60px);
  position: relative;
  display: flex;
}
