.sound__form {
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ccc;
  h6 {
    font-size: 18px;
    font-weight: bold;
  }
}

.sound__items-wrapper {
  margin-top: 16px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.sound__items-legend {
  display: flex;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  li {
    text-align: center;
  }
}

.sound__items-name {
  width: 200px;
  text-align: center;
  text-decoration: underline;
  &:hover {
    color: #555;
  }
}
.sound__items-image {
  width: 100px;
}
.sound__items-audio {
  width: 300px;
}
.sound__items-priority {
  width: 100px;
}
.sound__items-is_premium {
  width: 100px;
}
.sound__items-is_narrated {
  width: 100px;
}

.sound__item-wrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 12px;
  div div {
    text-align: center;
  }
  .sound__items-is_premium,
  .sound__items-is_narrated {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sound__add-btn {
  margin-left: 8px;
  width: 120px;
  height: 24px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  background-color: #7192be;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #434371;
    color: #fff;
  }
}

.sound__item {
  position: relative;
  padding: 30px 40px 60px;
  h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
  }
}

.sound__item-input-wrapper {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  label {
    font-size: 14px;
  }
  input[type="text"],
  input[type="number"] {
    margin-top: 3px;
    border: 1px solid #ccc;
    width: 200px;
    height: 20px;
    padding-left: 6px;
  }
  input[type="file"] {
    border: none;
    margin-top: 3px;
    padding-left: 0px;
  }
  input[type="checkbox"] {
    margin-left: 3px;
    margin-bottom: 1px;
  }
  textarea {
    border: 1px solid #ccc;
    margin-top: 3px;
    padding: 3px 6px;
    width: 200px;
    height: 100px;
  }
  select {
    width: 200px;
    height: 20px;
    margin-top: 3px;
    font-size: 12px;
    border: 1px solid #ccc;
  }
  .Dropdown-control {
    width: 200px;
    height: 20px;
    font-size: 12px;
    border: 1px solid #ccc;
    padding: 4px 0 0 4px;
  }
  .Dropdown-arrow {
    top: 8px;
  }
  .Dropdown-option {
    font-size: 14px;
    padding: 4px 4px;
  }
}

.sound__tags-selected {
  &.show-sound {
    margin-left: 0px;
  }
  margin-left: 12px;
  display: flex;
  .tag {
    margin-left: 6px;
    height: 20px;
    border: 1px solid #ccc;
    font-size: 12px;
    display: flex;
    justify-content: center;
    span {
      z-index: -1;
      margin-top: 3px;
      margin-left: 4px;
      margin-right: 3px;
      height: 12px;
    }
    &:hover {
      cursor: pointer;
    }
    &.show-sound:hover {
      cursor: default;
    }
  }
  .tag-deselect {
    z-index: -1;
    font-size: 12px;
    img {
      width: 10px;
      height: 10px;
      margin-right: 4px;
    }
  }
}

.easy-play-tag-notice {
  font-size: 12px;
  margin-bottom: 12px;
  color: #eb4034;
}

.sound__image-preview {
  width: 100px;
  height: 100px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  text-align: center;
  img {
    width: 100px;
    height: 100px;
  }
}

.sound__item-input-label {
  width: 140px;
  height: 20px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  label {
    font-size: 14px;
  }
  &.schedule {
    align-self: center;
  }
}

.sound__item-btn-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
  .submit {
    width: 120px;
    height: 36px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    border-radius: 6px;
    background-color: $pink;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #c7747f;
    }
  }
  .cancel {
    margin-left: 20px;
    width: 120px;
    height: 36px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    border-radius: 6px;
    background-color: #bebebe;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #aaa;
    }
  }
}

.sound__item-schedule-date-wrapper {
  font-size: 12px;
  select {
    width: 45px;
  }
  input {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 0 4px;
    height: 27px;
  }
  .react-datetime-picker__wrapper {
    border: none;
  }
  .react-datetime-picker__clock.react-datetime-picker__clock--open {
    display: none;
  }
  .react-datetime-picker__inputGroup__leadingZero {
    padding-left: 4px;
  }
  .react-datetime-picker__inputGroup__input--hasLeadingZero {
    padding-left: 0;
  }
  .react-datetime-picker__inputGroup__input:invalid {
    background: none;
  }
  .react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__hour,
  .react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__minute {
    width: 14px !important;
    text-align: right;
  }
  .react-datetime-picker__calendar .react-calendar {
    .react-calendar__tile.react-calendar__month-view__days__day {
      font-size: 14px;
    }
    .react-calendar__navigation__label {
      font-size: 18px;
    }
  }
}

.sound__item-show-wrapper {
  width: 100%;
  margin-bottom: 12px;
  position: relative;
  display: flex;
  align-items: center;
}

.sound__item-show-label {
  width: 140px;
  height: 20px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  label {
    font-size: 14px;
  }
}

.sound__item-show {
  margin-left: 10px;
}

.sound__item-show-content {
  font-size: 14px;
  &#sound-description {
    max-width: 600px;
  }
}

.sound__item-edit-btn {
  margin-top: 30px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: $pink;
  &:hover {
    background-color: #c7747f;
    color: #fff;
  }
}

.sound__item-back-btn {
  margin-left: 20px;
  margin-top: 30px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: #bebebe;
  &:hover {
    background-color: #aaa;
    color: #fff;
  }
}

.sound__item-delete-btn {
  margin-left: 20px;
  margin-top: 30px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: #ccc;
  &:hover {
    background-color: #bbb;
    color: #fff;
  }
}

.no-sounds {
  margin-top: 20px;
  padding-left: 20px;
  font-size: 14px;
}

.audio-quality-notice {
  font-size: 14px;
  color: #eb4034;
}
