.premieres-wrapper {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-left: 40px;

  h1 {
    font-size: 20px;
    font-weight: bold;
  }
}

.premieres__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1000px;
}

.premieres__total-amount {
  display: flex;
  align-items: center;
  margin-left: 40px;
  margin-top: 20px;
  .total-amount-label {
    font-weight: bold;
    font-size: 18px;
    margin-right: 20px;
  }
  .total-amount {
    font-size: 18px;
    margin-right: 10px;
  }
}

.premieres__month-picker-wrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  .previous,
  .next {
    width: 54px;
    height: 20px;
    font-size: 16px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    font-weight: bold;
    cursor: pointer;
    margin-right: 6px;
    &:hover {
      background-color: #aaa;
    }
    &.disabled {
      cursor: not-allowed;
      color: #fff;
      background-color: #eee;
    }
  }
  .year-and-month {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 20px;
    margin-right: 6px;
    font-size: 18px;
    font-weight: bold;
    .year {
      margin-right: 4px;
    }
  }
}

.premieres__table {
  margin-top: 30px;
  width: 1000px;
  border-radius: 2px;
  .premieres__table-legend {
    display: flex;
    align-items: center;
    width: 1000px;
    height: 30px;
    border-bottom: 2px solid #333;
    .legend {
      border-right: 1px solid #eee;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:last-child {
        border-right: none;
      }
    }
  }
  .name {
    width: 300px;
  }
  .role {
    width: 200px;
  }
  .premiered-at {
    width: 200px;
  }
  .paid {
    width: 100px;
  }
  .amount {
    width: 200px;
  }
  .premieres__table-item {
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid #ccc;
    &.no-item {
      justify-content: center;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
    }
    .role-content {
      font-size: 12px;
      margin-right: 4px;
    }
    .writer {
      width: 30px;
      height: 20px;
      border-radius: 4px;
      background-color: $pink;
      color: #fff;
      font-weight: bold;
    }
    .narrator {
      width: 50px;
      height: 20px;
      border-radius: 4px;
      background-color: $skyblue;
      color: #fff;
      font-weight: bold;
    }
    .producer {
      width: 50px;
      height: 20px;
      border-radius: 4px;
      background-color: $purple;
      color: #fff;
      font-weight: bold;
    }
    .pending {
      width: 50px;
      height: 20px;
      font-size: 12px;
      border-radius: 4px;
      background-color: #b7b6c2;
      color: #fff;
      font-weight: bold;
    }
    .finished {
      width: 50px;
      height: 20px;
      font-size: 12px;
      border-radius: 4px;
      background-color: #3e885b;
      color: #fff;
      font-weight: bold;
    }
  }
}
