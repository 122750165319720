.navbar {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
  background-color: $pink;
}
.navbar__links {
  display: flex;
  align-items: center;
  li {
    margin-left: 30px;
  }
}
.navbar-wrapper {
  height: 60px;
  width: 1440px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: white;
  a:hover {
    text-decoration: underline;
  }
}
