.register {
  width: 100%;
  height: 100%;
  padding-top: 60px;
}

.register__form {
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  position: relative;
  text-align: center;
}

.register__input-wrapper {
  width: 100%;
  height: 48px;
  margin-bottom: 24px;
  position: relative;
  &.description {
    height: 96px;
  }
}

.register__input-label {
  width: 400px;
  height: 18px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.register__input-label label {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.register__input input {
  width: 400px;
  height: 36px;
  font-size: 16px;
  border: 1px solid #ccc;
  padding-left: 10px;
}

.register__input.gender-select {
  padding-left: 100px;
  padding-top: 12px;
  text-align: left;
}

.register__input-gender {
  width: 200px;
  height: 24px;
}

.register__input textarea {
  width: 400px;
  height: 76px;
  font-size: 16px;
  border: 1px solid #ccc;
  padding-left: 10px;
  padding-top: 10px;
  resize: none;
}

.register__birthdate-select-wrapper {
  display: flex;
  align-items: center;
  padding-left: 100px;
  padding-top: 12px;
  .register__input {
    margin-right: 10px;
  }
}

.register__input-wrapper.profile-image {
  height: 200px;
}

input#profile_image {
  height: 24px;
  margin-top: 12px;
  border: none;
}

.profile-image-preview {
  margin-left: 112px;
  border: 1px solid #ccc;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  img {
    width: 100px;
    height: 100px;
  }
}

.previewText {
  font-size: 12px;
}

.password-error {
  padding-left: 100px;
  text-align: left;
  font-size: 10px;
  color: red;
}

.uniq-check {
  width: 60px;
  height: 36px;
  background-color: #eee;
  position: absolute;
  top: 18px;
  right: 30px;
  border-radius: 3px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: #ddd;
  }
}

.register__submit-btn {
  width: 400px;
  height: 48px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  background-color: #333;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #000;
  }
}
