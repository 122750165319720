.sidebar {
  width: 208px;
  height: 100%;
  background-color: #f9f9f9;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
}

.sidebar__links {
  li {
    width: 208px;
    height: 60px;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 16px;
    border-bottom: 1px solid #ccc;
    font-family: "Roboto";
    a {
      padding-left: 40px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
    &.active {
      background-color: #ddd;
      font-weight: bold;
    }
    &:hover {
      background-color: #ddd;
      text-decoration: underline;
    }
    .material-icons {
      font-size: 14px;
      margin-left: 4px;
      margin-bottom: 4px;
    }
  }
  button {
    padding-left: 40px;
    text-align: left;
    width: 100%;
    height: 100%;
    font-size: 16px;
    cursor: pointer;
  }
}
