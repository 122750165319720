.payment-request-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.3;
}
.payment-request-input-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.payment-request-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  .message {
    margin-top: 50px;
    width: 320px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
.payment-request-container {
  width: 400px;
  height: 256px;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  position: relative;
  padding: 24px;
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .input-wrapper {
    margin-top: 32px;
    .amount-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
    input {
      border: 1px solid #ccc;
      border-right: none;
      font-size: 14px;
      padding: 6px;
      width: 300px;
      text-align: right;
    }
    .won {
      width: 20px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      border-left: none;
      text-align: right;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
    .information {
      margin-top: 6px;
      margin-left: auto;
      margin-right: auto;
      width: 320px;
      font-size: 12px;
      text-align: right;
      color: #333;
    }
  }
  .btns-wrapper {
    margin-top: 30px;
    .submit {
      margin-left: auto;
      margin-right: auto;
      width: 320px;
      background-color: $pink;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      padding: 8px;
      text-align: center;
      cursor: pointer;
      border-radius: 6px;
      &:hover {
        background-color: #c7747f;
      }
      &.disabled {
        background-color: #666;
        cursor: not-allowed;
      }
    }
    .cancel {
      margin-top: 12px;
      margin-left: auto;
      margin-right: auto;
      width: 320px;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      cursor: pointer;
      padding: 8px;
      text-align: center;
      border-radius: 6px;
      background-color: #bebebe;
      &:hover {
        background-color: #aaa;
      }
    }
  }
}
