.channel {
  padding: 30px 40px 60px;
}

.channel h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
}

.channel__input-wrapper {
  width: 100%;
  margin-bottom: 12px;
  position: relative;
  display: flex;
  align-items: center;
}

.channel__input-label {
  width: 80px;
  height: 20px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  label {
    font-size: 14px;
  }
  &.schedule {
    align-self: center;
  }
}

.channel__show-wrapper {
  width: 100%;
  margin-bottom: 12px;
  position: relative;
  display: flex;
  align-items: center;
}

.channel__show-label {
  width: 80px;
  height: 20px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  label {
    font-size: 14px;
  }
}

.channel__show {
  margin-left: 10px;
}

.channel__show-content {
  font-size: 14px;
}

.channel__edit-btn-div {
  margin-top: 24px;
  display: flex;
  align-items: center;
}

.channel__edit-btn {
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: $pink;
  &:hover {
    background-color: #c7747f;
    color: #fff;
  }
}

.channel__input {
  display: flex;
  align-items: center;
  align-self: flex-start;
  input {
    width: 200px;
    height: 20px;
    font-size: 12px;
    border: 1px solid #ccc;
    padding-left: 6px;
  }
  textarea {
    width: 200px;
    height: 120px;
    font-size: 12px;
    border: 1px solid #ccc;
    padding-left: 6px;
    padding-top: 4px;
    resize: none;
  }
  select {
    width: 200px;
    height: 20px;
    font-size: 12px;
    border: 1px solid #ccc;
  }
  input[type="file"] {
    border: none;
  }
  .channel__schedule-date-wrapper {
    font-size: 12px;
    select {
      width: 45px;
    }
    input {
      border: none;
      border-bottom: 1px solid #ccc;
      padding: 0 4px;
      height: 27px;
    }
    .react-datetime-picker__wrapper {
      border: none;
    }
    .react-datetime-picker__clock.react-datetime-picker__clock--open {
      display: none;
    }
    .react-datetime-picker__inputGroup__leadingZero {
      padding-left: 4px;
    }
    .react-datetime-picker__inputGroup__input--hasLeadingZero {
      padding-left: 0;
    }
    .react-datetime-picker__inputGroup__input:invalid {
      background: none;
    }
    .react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__hour,
    .react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__minute {
      width: 14px !important;
      text-align: right;
    }
    .react-datetime-picker__calendar .react-calendar {
      .react-calendar__tile.react-calendar__month-view__days__day {
        font-size: 14px;
      }
      .react-calendar__navigation__label {
        font-size: 18px;
      }
    }
  }
}

.channel__image-preview {
  margin-left: 8px;
  width: 100px;
  height: 100px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  text-align: center;
  img {
    width: 100px;
    height: 100px;
  }
}

.channel__submit-btn {
  margin-top: 20px;
  width: 120px;
  height: 36px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: $pink;
  &:hover {
    background-color: #c7747f;
  }
}

.channel__cancel-btn {
  margin-left: 20px;
  width: 120px;
  height: 36px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: #bebebe;
  &:hover {
    background-color: #aaa;
  }
}

.channel__btns-wrapper {
  margin-bottom: 30px;
}

.channel__delete-btn {
  margin-left: 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: #ccc;
  &:hover {
    background-color: #bbb;
    color: #fff;
  }
}

.no-comment {
  margin-top: 20px;
  padding-left: 20px;
  font-size: 14px;
}
