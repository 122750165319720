.myinfo {
  width: calc(100% - 208px);
  height: 100%;
  padding-top: 30px;
  padding-left: 40px;
}
.popup-open {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 888;
}

.myinfo__info-wrapper {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ccc;
  position: relative;
}

.myinfo__item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  .hidden {
    display: none;
  }
  .active {
    display: block;
  }
  &.description-wrapper {
    width: calc(100% - 250px);
  }
}

.myinfo__item > div {
  display: flex;
  align-items: center;
  margin-right: 12px;
  .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.7;
    margin-right: 8px;
  }
  .value {
    font-size: 16px;
    line-height: 1.7;
  }
  input {
    border: 1px solid #ccc;
    padding-left: 8px;
    font-size: 14px;
  }
  textarea {
    border: 1px solid #ccc;
    padding-left: 8px;
    padding-top: 6px;
    resize: none;
    font-size: 14px;
  }
}

.myinfo__btn {
  width: 30px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  margin-right: 10px;
  &.cancel {
    background-color: #ddd;
    &:hover {
      background-color: #bcbcbc;
    }
  }
  &.change {
    background-color: $pink;
    &:hover {
      background-color: #c7747f;
    }
  }
  &.save {
    background-color: #000;
    &:hover {
      background-color: #333;
    }
  }
  &.upload-image {
    margin-top: 8px;
    margin-left: 70px;
    width: 60px;
    background-color: $pink;
    &:hover {
      background-color: #c7747f;
    }
  }
}

.myinfo__password-change-btn {
  margin-top: 20px;
  width: 120px;
  height: 36px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: $pink;
  &:hover {
    background-color: #c7747f;
  }
}

.myinfo__profile-image-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  height: 300px;
  input {
    margin-top: 12px;
  }
  .hidden {
    display: none;
  }
}

.myinfo__profile-image-preview {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.myinfo__item-birth-date-update-wrapper {
  display: flex;
  align-items: center;
  .myinfo__btn.change {
    margin-left: 12px;
  }
}
