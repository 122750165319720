.revenue-share-wrapper {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-left: 40px;

  h1 {
    font-size: 20px;
    font-weight: bold;
  }
}

.revenue-share__row {
  margin-top: 30px;
  width: 720px;

  .confirmed-income {
    display: flex;
    align-items: center;
    .title {
      font-size: 18px;
      font-weight: bold;
    }
    .checkout-btn {
      margin-left: 12px;
      width: 70px;
      height: 24px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $pink;
      color: white;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        background-color: #c7747f;
      }
      &.disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }
  }
  .confirmed-amount {
    margin-top: 3px;
    font-size: 16px;
  }
  .expected-income {
    font-weight: bold;
    display: flex;
    .expected-details {
      font-weight: 400;
      margin-left: 12px;
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        font-weight: bold;
      }
    }
  }
  .expected-amount {
    margin-top: 3px;
  }
  .revenue-share-history {
    margin-top: 30px;
  }
  .revenue-share-history-title {
    font-weight: bold;
  }
  .rs-table {
    margin-top: 10px;
    width: 720px;
    border-radius: 2px;
    .rs-table-legend {
      display: flex;
      align-items: center;
      width: 720px;
      height: 30px;
      border-bottom: 2px solid #333;
      .legend {
        border-right: 1px solid #eee;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-child {
          border-right: none;
        }
      }
    }
    .year-month {
      width: 300px;
    }
    .amount {
      width: 200px;
    }
    .details {
      width: 200px;
      font-weight: 400;
      margin-left: 12px;
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        font-weight: bold;
      }
    }
    .rs-table-item {
      display: flex;
      align-items: center;
      height: 40px;
      border-bottom: 1px solid #ccc;
      &.no-item {
        justify-content: center;
        .no-content {
          color: #666;
        }
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
      }
    }
  }
}
