.login {
  width: 100%;
  height: 100%;
  padding-top: 120px;
}

.login__form {
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  height: 240px;
  position: relative;
  text-align: center;
}

.login__input-wrapper {
  width: 100%;
  height: 48px;
  margin-bottom: 48px;
}

.login__input-label {
  width: 400px;
  height: 18px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-bottom: 12px;
}

.login__input-label label {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.login__input input {
  width: 400px;
  height: 36px;
  font-size: 16px;
  border: 1px solid #000;
  padding-left: 10px;
}

.login__button {
  width: 400px;
  height: 48px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  background-color: #333;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-account {
  margin-top: 36px;
  width: 400px;
  height: 24px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.7;
}

.register-link {
  color: blue;
  &:hover {
    color: blue;
    text-decoration: underline;
  }
}
