.navbar-channels {
  height: 70px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.channels__navbar-list {
  display: flex;
  align-items: center;
}
.channels__navbar-tab {
  width: 300px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ccc;
  &:hover {
    cursor: pointer;
  }
  &.selected {
    border-bottom: 3px solid #333;
    background-color: #eee;
  }
  .btn__channels-navbar {
    width: 300px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      font-weight: bold;
    }
  }
}

.navbar-sounds {
  margin-top: 10px;
  height: 30px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
}

.sounds__navbar-list {
  display: flex;
  align-items: center;
}
.sounds__navbar-tab {
  width: 300px;
  height: 30px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
  &.selected {
    border-bottom: 3px solid #333;
    background-color: #eee;
  }
  .btn__sounds-navbar {
    width: 300px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.btn__new-channel {
  width: 120px;
  height: 40px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #333;
  background-color: #78b6ff;
  color: #fff;
  font-weight: bold;
  &:hover {
    background-color: #6d86e8;
    color: #fff;
    font-weight: bold;
  }
}

.btn__new-channel {
  width: 120px;
  height: 40px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #333;
  background-color: #78b6ff;
  color: #fff;

  &:hover {
    background-color: #6d86e8;
    color: #fff;
  }
}

.channel-list-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  padding-left: 60px;
  padding-top: 40px;
}

.channel-list {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
}

.channel-item {
  width: 200px;
  height: 240px;
  position: relative;
  cursor: pointer;
  .channel-item__wrapper {
    width: 200px;
    height: 200px;
    position: relative;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  }
  img {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  &:hover {
    font-weight: bold;
  }
}

.channel-edit__link-image {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.channel-edit__link {
  font-size: 16px;
}
.channel-edit__link-title {
  margin-top: 10px;
}

.no-channels {
  margin-top: 20px;
  padding-left: 20px;
  font-size: 14px;
}
