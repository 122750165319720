.revenue-share-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.3;
}
.revenue-share-details-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.revenue-share-container {
  width: 768px;
  height: 800px;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  position: relative;
  padding: 24px;
  .close {
    position: absolute;
    top: 24px;
    right: 24px;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .total-income {
    margin-top: 6px;
  }
  .expected-income-details {
    margin-top: 30px;
  }
  .expected-income-details-title {
    font-weight: bold;
  }
  .expected-income-table {
    margin-top: 10px;
    width: 720px;
    border-radius: 2px;
    .expected-income-table-legend {
      display: flex;
      align-items: center;
      width: 720px;
      height: 30px;
      border-bottom: 2px solid #333;
      .legend {
        border-right: 1px solid #333;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        &:last-child {
          border-right: none;
        }
      }
    }
    .name {
      width: 300px;
    }
    .role {
      width: 200px;
    }
    .share-amount {
      width: 200px;
    }
    .expected-income-table-content {
      height: 628px;
      overflow: auto;
      border: 2px solid #000;
      border-top: none;
    }
    .expected-income-table-item {
      display: flex;
      align-items: center;
      height: 40px;
      border-bottom: 1px solid #ccc;
      &.no-item {
        justify-content: center;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
      }
      .role-content {
        font-size: 12px;
        margin-right: 4px;
      }
      .writer {
        width: 30px;
        height: 20px;
        border-radius: 4px;
        background-color: $pink;
        color: #fff;
        font-weight: bold;
      }
      .narrator {
        width: 50px;
        height: 20px;
        border-radius: 4px;
        background-color: $skyblue;
        color: #fff;
        font-weight: bold;
      }
      .producer {
        width: 50px;
        height: 20px;
        border-radius: 4px;
        background-color: $purple;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}
