@import url(https://fonts.googleapis.com/css?family=Roboto:300,700&display=swap);
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  height: 100%;
}

body {
  margin: auto;
  height: 100%;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

button {
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:visited {
  color: inherit;
}

.hidden {
  display: none;
}

textarea {
  resize: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

a {
  text-decoration: none;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

input,
button {
  border: none;
  outline: none;
  padding: 0;
  box-shadow: none;
  font-family: inherit;
}

#root {
  height: 100%;
  width: 100%;
  position: relative;
  font-family: "Roboto", sans-serif;
}

.loading-fullpage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame {
  width: 100%;
  min-width: 1024px;
  height: 100%;
  position: relative;
}

.wrapper {
  width: 100%;
  height: calc(100% - 60px);
  position: relative;
  display: flex;
}

.navbar {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #e7717d;
}

.navbar__links {
  display: flex;
  align-items: center;
}
.navbar__links li {
  margin-left: 30px;
}

.navbar-wrapper {
  height: 60px;
  width: 1440px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: white;
}
.navbar-wrapper a:hover {
  text-decoration: underline;
}

.sidebar {
  width: 208px;
  height: 100%;
  background-color: #f9f9f9;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
}

.sidebar__links li {
  width: 208px;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  border-bottom: 1px solid #ccc;
  font-family: "Roboto";
}
.sidebar__links li a {
  padding-left: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.sidebar__links li.active {
  background-color: #ddd;
  font-weight: bold;
}
.sidebar__links li:hover {
  background-color: #ddd;
  text-decoration: underline;
}
.sidebar__links li .material-icons {
  font-size: 14px;
  margin-left: 4px;
  margin-bottom: 4px;
}
.sidebar__links button {
  padding-left: 40px;
  text-align: left;
  width: 100%;
  height: 100%;
  font-size: 16px;
  cursor: pointer;
}

.pageview {
  width: 100%;
  height: 100%;
}

.navbar-channels {
  height: 70px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.channels__navbar-list {
  display: flex;
  align-items: center;
}

.channels__navbar-tab {
  width: 300px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ccc;
}
.channels__navbar-tab:hover {
  cursor: pointer;
}
.channels__navbar-tab.selected {
  border-bottom: 3px solid #333;
  background-color: #eee;
}
.channels__navbar-tab .btn__channels-navbar {
  width: 300px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.channels__navbar-tab .btn__channels-navbar:hover {
  font-weight: bold;
}

.navbar-sounds {
  margin-top: 10px;
  height: 30px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
}

.sounds__navbar-list {
  display: flex;
  align-items: center;
}

.sounds__navbar-tab {
  width: 300px;
  height: 30px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sounds__navbar-tab:hover {
  cursor: pointer;
  background-color: #eee;
}
.sounds__navbar-tab.selected {
  border-bottom: 3px solid #333;
  background-color: #eee;
}
.sounds__navbar-tab .btn__sounds-navbar {
  width: 300px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn__new-channel {
  width: 120px;
  height: 40px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #333;
  background-color: #78b6ff;
  color: #fff;
  font-weight: bold;
}
.btn__new-channel:hover {
  background-color: #6d86e8;
  color: #fff;
  font-weight: bold;
}

.btn__new-channel {
  width: 120px;
  height: 40px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #333;
  background-color: #78b6ff;
  color: #fff;
}
.btn__new-channel:hover {
  background-color: #6d86e8;
  color: #fff;
}

.channel-list-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  padding-left: 60px;
  padding-top: 40px;
}

.channel-list {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
}

.channel-item {
  width: 200px;
  height: 240px;
  position: relative;
  cursor: pointer;
}
.channel-item .channel-item__wrapper {
  width: 200px;
  height: 200px;
  position: relative;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
}
.channel-item img {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.channel-item:hover {
  font-weight: bold;
}

.channel-edit__link-image {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.channel-edit__link {
  font-size: 16px;
}

.channel-edit__link-title {
  margin-top: 10px;
}

.no-channels {
  margin-top: 20px;
  padding-left: 20px;
  font-size: 14px;
}

.channel {
  padding: 30px 40px 60px;
}

.channel h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
}

.channel__input-wrapper {
  width: 100%;
  margin-bottom: 12px;
  position: relative;
  display: flex;
  align-items: center;
}

.channel__input-label {
  width: 80px;
  height: 20px;
  display: flex;
  align-items: center;
  align-self: flex-start;
}
.channel__input-label label {
  font-size: 14px;
}
.channel__input-label.schedule {
  align-self: center;
}

.channel__show-wrapper {
  width: 100%;
  margin-bottom: 12px;
  position: relative;
  display: flex;
  align-items: center;
}

.channel__show-label {
  width: 80px;
  height: 20px;
  display: flex;
  align-items: center;
  align-self: flex-start;
}
.channel__show-label label {
  font-size: 14px;
}

.channel__show {
  margin-left: 10px;
}

.channel__show-content {
  font-size: 14px;
}

.channel__edit-btn-div {
  margin-top: 24px;
  display: flex;
  align-items: center;
}

.channel__edit-btn {
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: #e7717d;
}
.channel__edit-btn:hover {
  background-color: #c7747f;
  color: #fff;
}

.channel__input {
  display: flex;
  align-items: center;
  align-self: flex-start;
}
.channel__input input {
  width: 200px;
  height: 20px;
  font-size: 12px;
  border: 1px solid #ccc;
  padding-left: 6px;
}
.channel__input textarea {
  width: 200px;
  height: 120px;
  font-size: 12px;
  border: 1px solid #ccc;
  padding-left: 6px;
  padding-top: 4px;
  resize: none;
}
.channel__input select {
  width: 200px;
  height: 20px;
  font-size: 12px;
  border: 1px solid #ccc;
}
.channel__input input[type=file] {
  border: none;
}
.channel__input .channel__schedule-date-wrapper {
  font-size: 12px;
}
.channel__input .channel__schedule-date-wrapper select {
  width: 45px;
}
.channel__input .channel__schedule-date-wrapper input {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0 4px;
  height: 27px;
}
.channel__input .channel__schedule-date-wrapper .react-datetime-picker__wrapper {
  border: none;
}
.channel__input .channel__schedule-date-wrapper .react-datetime-picker__clock.react-datetime-picker__clock--open {
  display: none;
}
.channel__input .channel__schedule-date-wrapper .react-datetime-picker__inputGroup__leadingZero {
  padding-left: 4px;
}
.channel__input .channel__schedule-date-wrapper .react-datetime-picker__inputGroup__input--hasLeadingZero {
  padding-left: 0;
}
.channel__input .channel__schedule-date-wrapper .react-datetime-picker__inputGroup__input:invalid {
  background: none;
}
.channel__input .channel__schedule-date-wrapper .react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__hour,
.channel__input .channel__schedule-date-wrapper .react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__minute {
  width: 14px !important;
  text-align: right;
}
.channel__input .channel__schedule-date-wrapper .react-datetime-picker__calendar .react-calendar .react-calendar__tile.react-calendar__month-view__days__day {
  font-size: 14px;
}
.channel__input .channel__schedule-date-wrapper .react-datetime-picker__calendar .react-calendar .react-calendar__navigation__label {
  font-size: 18px;
}

.channel__image-preview {
  margin-left: 8px;
  width: 100px;
  height: 100px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  text-align: center;
}
.channel__image-preview img {
  width: 100px;
  height: 100px;
}

.channel__submit-btn {
  margin-top: 20px;
  width: 120px;
  height: 36px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: #e7717d;
}
.channel__submit-btn:hover {
  background-color: #c7747f;
}

.channel__cancel-btn {
  margin-left: 20px;
  width: 120px;
  height: 36px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: #bebebe;
}
.channel__cancel-btn:hover {
  background-color: #aaa;
}

.channel__btns-wrapper {
  margin-bottom: 30px;
}

.channel__delete-btn {
  margin-left: 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: #ccc;
}
.channel__delete-btn:hover {
  background-color: #bbb;
  color: #fff;
}

.no-comment {
  margin-top: 20px;
  padding-left: 20px;
  font-size: 14px;
}

.sound__form {
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ccc;
}
.sound__form h6 {
  font-size: 18px;
  font-weight: bold;
}

.sound__items-wrapper {
  margin-top: 16px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.sound__items-legend {
  display: flex;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}
.sound__items-legend li {
  text-align: center;
}

.sound__items-name {
  width: 200px;
  text-align: center;
  text-decoration: underline;
}
.sound__items-name:hover {
  color: #555;
}

.sound__items-image {
  width: 100px;
}

.sound__items-audio {
  width: 300px;
}

.sound__items-priority {
  width: 100px;
}

.sound__items-is_premium {
  width: 100px;
}

.sound__items-is_narrated {
  width: 100px;
}

.sound__item-wrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 12px;
}
.sound__item-wrapper div div {
  text-align: center;
}
.sound__item-wrapper .sound__items-is_premium,
.sound__item-wrapper .sound__items-is_narrated {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sound__add-btn {
  margin-left: 8px;
  width: 120px;
  height: 24px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  background-color: #7192be;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sound__add-btn:hover {
  background-color: #434371;
  color: #fff;
}

.sound__item {
  position: relative;
  padding: 30px 40px 60px;
}
.sound__item h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
}

.sound__item-input-wrapper {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.sound__item-input-wrapper label {
  font-size: 14px;
}
.sound__item-input-wrapper input[type=text],
.sound__item-input-wrapper input[type=number] {
  margin-top: 3px;
  border: 1px solid #ccc;
  width: 200px;
  height: 20px;
  padding-left: 6px;
}
.sound__item-input-wrapper input[type=file] {
  border: none;
  margin-top: 3px;
  padding-left: 0px;
}
.sound__item-input-wrapper input[type=checkbox] {
  margin-left: 3px;
  margin-bottom: 1px;
}
.sound__item-input-wrapper textarea {
  border: 1px solid #ccc;
  margin-top: 3px;
  padding: 3px 6px;
  width: 200px;
  height: 100px;
}
.sound__item-input-wrapper select {
  width: 200px;
  height: 20px;
  margin-top: 3px;
  font-size: 12px;
  border: 1px solid #ccc;
}
.sound__item-input-wrapper .Dropdown-control {
  width: 200px;
  height: 20px;
  font-size: 12px;
  border: 1px solid #ccc;
  padding: 4px 0 0 4px;
}
.sound__item-input-wrapper .Dropdown-arrow {
  top: 8px;
}
.sound__item-input-wrapper .Dropdown-option {
  font-size: 14px;
  padding: 4px 4px;
}

.sound__tags-selected {
  margin-left: 12px;
  display: flex;
}
.sound__tags-selected.show-sound {
  margin-left: 0px;
}
.sound__tags-selected .tag {
  margin-left: 6px;
  height: 20px;
  border: 1px solid #ccc;
  font-size: 12px;
  display: flex;
  justify-content: center;
}
.sound__tags-selected .tag span {
  z-index: -1;
  margin-top: 3px;
  margin-left: 4px;
  margin-right: 3px;
  height: 12px;
}
.sound__tags-selected .tag:hover {
  cursor: pointer;
}
.sound__tags-selected .tag.show-sound:hover {
  cursor: default;
}
.sound__tags-selected .tag-deselect {
  z-index: -1;
  font-size: 12px;
}
.sound__tags-selected .tag-deselect img {
  width: 10px;
  height: 10px;
  margin-right: 4px;
}

.easy-play-tag-notice {
  font-size: 12px;
  margin-bottom: 12px;
  color: #eb4034;
}

.sound__image-preview {
  width: 100px;
  height: 100px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  text-align: center;
}
.sound__image-preview img {
  width: 100px;
  height: 100px;
}

.sound__item-input-label {
  width: 140px;
  height: 20px;
  display: flex;
  align-items: center;
  align-self: flex-start;
}
.sound__item-input-label label {
  font-size: 14px;
}
.sound__item-input-label.schedule {
  align-self: center;
}

.sound__item-btn-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.sound__item-btn-wrapper .submit {
  width: 120px;
  height: 36px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: #e7717d;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sound__item-btn-wrapper .submit:hover {
  background-color: #c7747f;
}
.sound__item-btn-wrapper .cancel {
  margin-left: 20px;
  width: 120px;
  height: 36px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: #bebebe;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sound__item-btn-wrapper .cancel:hover {
  background-color: #aaa;
}

.sound__item-schedule-date-wrapper {
  font-size: 12px;
}
.sound__item-schedule-date-wrapper select {
  width: 45px;
}
.sound__item-schedule-date-wrapper input {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0 4px;
  height: 27px;
}
.sound__item-schedule-date-wrapper .react-datetime-picker__wrapper {
  border: none;
}
.sound__item-schedule-date-wrapper .react-datetime-picker__clock.react-datetime-picker__clock--open {
  display: none;
}
.sound__item-schedule-date-wrapper .react-datetime-picker__inputGroup__leadingZero {
  padding-left: 4px;
}
.sound__item-schedule-date-wrapper .react-datetime-picker__inputGroup__input--hasLeadingZero {
  padding-left: 0;
}
.sound__item-schedule-date-wrapper .react-datetime-picker__inputGroup__input:invalid {
  background: none;
}
.sound__item-schedule-date-wrapper .react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__hour,
.sound__item-schedule-date-wrapper .react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__minute {
  width: 14px !important;
  text-align: right;
}
.sound__item-schedule-date-wrapper .react-datetime-picker__calendar .react-calendar .react-calendar__tile.react-calendar__month-view__days__day {
  font-size: 14px;
}
.sound__item-schedule-date-wrapper .react-datetime-picker__calendar .react-calendar .react-calendar__navigation__label {
  font-size: 18px;
}

.sound__item-show-wrapper {
  width: 100%;
  margin-bottom: 12px;
  position: relative;
  display: flex;
  align-items: center;
}

.sound__item-show-label {
  width: 140px;
  height: 20px;
  display: flex;
  align-items: center;
  align-self: flex-start;
}
.sound__item-show-label label {
  font-size: 14px;
}

.sound__item-show {
  margin-left: 10px;
}

.sound__item-show-content {
  font-size: 14px;
}
.sound__item-show-content#sound-description {
  max-width: 600px;
}

.sound__item-edit-btn {
  margin-top: 30px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: #e7717d;
}
.sound__item-edit-btn:hover {
  background-color: #c7747f;
  color: #fff;
}

.sound__item-back-btn {
  margin-left: 20px;
  margin-top: 30px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: #bebebe;
}
.sound__item-back-btn:hover {
  background-color: #aaa;
  color: #fff;
}

.sound__item-delete-btn {
  margin-left: 20px;
  margin-top: 30px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: #ccc;
}
.sound__item-delete-btn:hover {
  background-color: #bbb;
  color: #fff;
}

.no-sounds {
  margin-top: 20px;
  padding-left: 20px;
  font-size: 14px;
}

.audio-quality-notice {
  font-size: 14px;
  color: #eb4034;
}

.comment__list {
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}
.comment__list h6 {
  font-size: 18px;
  font-weight: bold;
}

.comment__items-wrapper {
  margin-top: 16px;
  width: 100%;
  position: relative;
}

.comment__item {
  display: flex;
  align-items: center;
  height: 44px;
  position: relative;
  border-bottom: 1px solid #f1f1f1;
}
.comment__item .commenter-image {
  width: 24px;
  height: 24px;
}
.comment__item .commenter-image img {
  width: 24px;
  height: 24px;
}
.comment__item .commenter-name {
  margin-left: 12px;
  width: 120px;
}
.comment__item .content {
  width: calc(100% - 380px);
  text-align: center;
}
.comment__item .created-at {
  width: 160px;
  font-size: 12px;
}
.comment__item .likes-count {
  width: 64px;
  text-align: center;
}
.comment__item .label__created-at {
  width: 160px;
}
.comment__item .label__commenter {
  width: 156px;
}
.comment__item .label__content {
  width: calc(100% - 380px);
}
.comment__item .label__likes-count {
  width: 64px;
}
.comment__item.legend {
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  text-align: center;
}
.comment__item.best .best-icon {
  position: absolute;
  top: 0;
  left: 0;
}
.comment__item.best .best-icon img {
  width: 20px;
  height: 11px;
}

.login {
  width: 100%;
  height: 100%;
  padding-top: 120px;
}

.login__form {
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  height: 240px;
  position: relative;
  text-align: center;
}

.login__input-wrapper {
  width: 100%;
  height: 48px;
  margin-bottom: 48px;
}

.login__input-label {
  width: 400px;
  height: 18px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-bottom: 12px;
}

.login__input-label label {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.login__input input {
  width: 400px;
  height: 36px;
  font-size: 16px;
  border: 1px solid #000;
  padding-left: 10px;
}

.login__button {
  width: 400px;
  height: 48px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  background-color: #333;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-account {
  margin-top: 36px;
  width: 400px;
  height: 24px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.7;
}

.register-link {
  color: blue;
}
.register-link:hover {
  color: blue;
  text-decoration: underline;
}

.register {
  width: 100%;
  height: 100%;
  padding-top: 60px;
}

.register__form {
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  position: relative;
  text-align: center;
}

.register__input-wrapper {
  width: 100%;
  height: 48px;
  margin-bottom: 24px;
  position: relative;
}
.register__input-wrapper.description {
  height: 96px;
}

.register__input-label {
  width: 400px;
  height: 18px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.register__input-label label {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.register__input input {
  width: 400px;
  height: 36px;
  font-size: 16px;
  border: 1px solid #ccc;
  padding-left: 10px;
}

.register__input.gender-select {
  padding-left: 100px;
  padding-top: 12px;
  text-align: left;
}

.register__input-gender {
  width: 200px;
  height: 24px;
}

.register__input textarea {
  width: 400px;
  height: 76px;
  font-size: 16px;
  border: 1px solid #ccc;
  padding-left: 10px;
  padding-top: 10px;
  resize: none;
}

.register__birthdate-select-wrapper {
  display: flex;
  align-items: center;
  padding-left: 100px;
  padding-top: 12px;
}
.register__birthdate-select-wrapper .register__input {
  margin-right: 10px;
}

.register__input-wrapper.profile-image {
  height: 200px;
}

input#profile_image {
  height: 24px;
  margin-top: 12px;
  border: none;
}

.profile-image-preview {
  margin-left: 112px;
  border: 1px solid #ccc;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
}
.profile-image-preview img {
  width: 100px;
  height: 100px;
}

.previewText {
  font-size: 12px;
}

.password-error {
  padding-left: 100px;
  text-align: left;
  font-size: 10px;
  color: red;
}

.uniq-check {
  width: 60px;
  height: 36px;
  background-color: #eee;
  position: absolute;
  top: 18px;
  right: 30px;
  border-radius: 3px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
}
.uniq-check:hover {
  background-color: #ddd;
}

.register__submit-btn {
  width: 400px;
  height: 48px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  background-color: #333;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register__submit-btn:hover {
  background-color: #000;
}

.myinfo {
  width: calc(100% - 208px);
  height: 100%;
  padding-top: 30px;
  padding-left: 40px;
}

.popup-open {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 888;
}

.myinfo__info-wrapper {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ccc;
  position: relative;
}

.myinfo__item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.myinfo__item .hidden {
  display: none;
}
.myinfo__item .active {
  display: block;
}
.myinfo__item.description-wrapper {
  width: calc(100% - 250px);
}

.myinfo__item > div {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.myinfo__item > div .title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.7;
  margin-right: 8px;
}
.myinfo__item > div .value {
  font-size: 16px;
  line-height: 1.7;
}
.myinfo__item > div input {
  border: 1px solid #ccc;
  padding-left: 8px;
  font-size: 14px;
}
.myinfo__item > div textarea {
  border: 1px solid #ccc;
  padding-left: 8px;
  padding-top: 6px;
  resize: none;
  font-size: 14px;
}

.myinfo__btn {
  width: 30px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  margin-right: 10px;
}
.myinfo__btn.cancel {
  background-color: #ddd;
}
.myinfo__btn.cancel:hover {
  background-color: #bcbcbc;
}
.myinfo__btn.change {
  background-color: #e7717d;
}
.myinfo__btn.change:hover {
  background-color: #c7747f;
}
.myinfo__btn.save {
  background-color: #000;
}
.myinfo__btn.save:hover {
  background-color: #333;
}
.myinfo__btn.upload-image {
  margin-top: 8px;
  margin-left: 70px;
  width: 60px;
  background-color: #e7717d;
}
.myinfo__btn.upload-image:hover {
  background-color: #c7747f;
}

.myinfo__password-change-btn {
  margin-top: 20px;
  width: 120px;
  height: 36px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  background-color: #e7717d;
}
.myinfo__password-change-btn:hover {
  background-color: #c7747f;
}

.myinfo__profile-image-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  height: 300px;
}
.myinfo__profile-image-wrapper input {
  margin-top: 12px;
}
.myinfo__profile-image-wrapper .hidden {
  display: none;
}

.myinfo__profile-image-preview {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.myinfo__item-birth-date-update-wrapper {
  display: flex;
  align-items: center;
}
.myinfo__item-birth-date-update-wrapper .myinfo__btn.change {
  margin-left: 12px;
}

.popup {
  position: absolute;
  top: 200px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 1001;
  background-color: #ebebeb;
  border: 1px solid #bbb;
  border-radius: 3px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
}

.popup-wrapper {
  position: relative;
  height: 300px;
  width: 700px;
  padding-top: 60px;
}

.popup__input-wrapper {
  width: 100%;
  margin-bottom: 24px;
}
.popup__input-wrapper .information {
  font-size: 12px;
  color: #e7717d;
  margin-bottom: 20px;
  margin-left: 150px;
}

.popup__input-label {
  width: 400px;
  height: 18px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-bottom: 12px;
}

.popup__input-label label {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.popup__input .error {
  color: #e7717d;
  font-size: 12px;
  margin-left: 150px;
  margin-top: 8px;
}

.popup__input input {
  margin-left: 150px;
  width: 400px;
  height: 36px;
  font-size: 16px;
  border: 1px solid #000;
  padding-left: 10px;
}

.popup__submit-btn {
  width: 400px;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  background-color: #333;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup__submit-btn:hover {
  background-color: #444;
}
.popup__submit-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.popup.password-change {
  width: 700px;
  height: 400px;
}
.popup.password-change .authentication-popup__input-wrapper {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.popup.password-change .authentication-popup__input-wrapper label {
  display: block;
}
.popup.password-change .close-popup {
  position: absolute;
  top: 10px;
  right: 30px;
  font-size: 14px;
  cursor: pointer;
}
.popup.password-change .close-popup:hover {
  text-decoration: underline;
}

.popup.password-change .popup-wrapper {
  padding-top: 40px;
}

.analytics {
  width: calc(100% - 208px);
  height: 100%;
  padding-top: 30px;
  padding-left: 40px;
}
.analytics h1 {
  font-size: 20px;
  font-weight: bold;
}

.analytics__row {
  margin-top: 20px;
  padding-top: 12px;
  padding-bottom: 60px;
}
.analytics__row .title {
  font-size: 18px;
  font-weight: bold;
}
.analytics__row .content {
  margin-top: 8px;
}

.analytics__list-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
}
.analytics__list-item div {
  height: 32px;
}

.analytics__list-item.legend {
  font-weight: bold;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
}

.analytics__list-item-title {
  width: 40%;
  display: flex;
  align-items: center;
}

.analytics__list-item-content {
  width: 60%;
  display: flex;
  align-items: center;
}

.settlements {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-left: 40px;
}
.settlements h1 {
  font-size: 20px;
  font-weight: bold;
}

.settlements-table {
  margin-top: 20px;
  padding-top: 12px;
  padding-bottom: 60px;
}

.settlements__navbar-list {
  width: calc(100% - 208px);
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
}

.settlements__navbar-tab {
  width: 50%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.settlements__navbar-tab:hover {
  cursor: pointer;
}
.settlements__navbar-tab.selected {
  border-bottom: 3px solid #333;
  font-weight: bold;
  background-color: #eee;
}
.settlements__navbar-tab .btn__settlements-navbar {
  width: 50%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.settlements__navbar-tab .btn__settlements-navbar:hover {
  font-weight: bold;
}

.premieres-wrapper {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-left: 40px;
}
.premieres-wrapper h1 {
  font-size: 20px;
  font-weight: bold;
}

.premieres__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1000px;
}

.premieres__total-amount {
  display: flex;
  align-items: center;
  margin-left: 40px;
  margin-top: 20px;
}
.premieres__total-amount .total-amount-label {
  font-weight: bold;
  font-size: 18px;
  margin-right: 20px;
}
.premieres__total-amount .total-amount {
  font-size: 18px;
  margin-right: 10px;
}

.premieres__month-picker-wrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.premieres__month-picker-wrapper .previous,
.premieres__month-picker-wrapper .next {
  width: 54px;
  height: 20px;
  font-size: 16px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  font-weight: bold;
  cursor: pointer;
  margin-right: 6px;
}
.premieres__month-picker-wrapper .previous:hover,
.premieres__month-picker-wrapper .next:hover {
  background-color: #aaa;
}
.premieres__month-picker-wrapper .previous.disabled,
.premieres__month-picker-wrapper .next.disabled {
  cursor: not-allowed;
  color: #fff;
  background-color: #eee;
}
.premieres__month-picker-wrapper .year-and-month {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 20px;
  margin-right: 6px;
  font-size: 18px;
  font-weight: bold;
}
.premieres__month-picker-wrapper .year-and-month .year {
  margin-right: 4px;
}

.premieres__table {
  margin-top: 30px;
  width: 1000px;
  border-radius: 2px;
}
.premieres__table .premieres__table-legend {
  display: flex;
  align-items: center;
  width: 1000px;
  height: 30px;
  border-bottom: 2px solid #333;
}
.premieres__table .premieres__table-legend .legend {
  border-right: 1px solid #eee;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.premieres__table .premieres__table-legend .legend:last-child {
  border-right: none;
}
.premieres__table .name {
  width: 300px;
}
.premieres__table .role {
  width: 200px;
}
.premieres__table .premiered-at {
  width: 200px;
}
.premieres__table .paid {
  width: 100px;
}
.premieres__table .amount {
  width: 200px;
}
.premieres__table .premieres__table-item {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #ccc;
}
.premieres__table .premieres__table-item.no-item {
  justify-content: center;
}
.premieres__table .premieres__table-item div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.premieres__table .premieres__table-item .role-content {
  font-size: 12px;
  margin-right: 4px;
}
.premieres__table .premieres__table-item .writer {
  width: 30px;
  height: 20px;
  border-radius: 4px;
  background-color: #e7717d;
  color: #fff;
  font-weight: bold;
}
.premieres__table .premieres__table-item .narrator {
  width: 50px;
  height: 20px;
  border-radius: 4px;
  background-color: #5bc0eb;
  color: #fff;
  font-weight: bold;
}
.premieres__table .premieres__table-item .producer {
  width: 50px;
  height: 20px;
  border-radius: 4px;
  background-color: #9368b7;
  color: #fff;
  font-weight: bold;
}
.premieres__table .premieres__table-item .pending {
  width: 50px;
  height: 20px;
  font-size: 12px;
  border-radius: 4px;
  background-color: #b7b6c2;
  color: #fff;
  font-weight: bold;
}
.premieres__table .premieres__table-item .finished {
  width: 50px;
  height: 20px;
  font-size: 12px;
  border-radius: 4px;
  background-color: #3e885b;
  color: #fff;
  font-weight: bold;
}

.revenue-share-wrapper {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-left: 40px;
}
.revenue-share-wrapper h1 {
  font-size: 20px;
  font-weight: bold;
}

.revenue-share__row {
  margin-top: 30px;
  width: 720px;
}
.revenue-share__row .confirmed-income {
  display: flex;
  align-items: center;
}
.revenue-share__row .confirmed-income .title {
  font-size: 18px;
  font-weight: bold;
}
.revenue-share__row .confirmed-income .checkout-btn {
  margin-left: 12px;
  width: 70px;
  height: 24px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e7717d;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
.revenue-share__row .confirmed-income .checkout-btn:hover {
  background-color: #c7747f;
}
.revenue-share__row .confirmed-income .checkout-btn.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.revenue-share__row .confirmed-amount {
  margin-top: 3px;
  font-size: 16px;
}
.revenue-share__row .expected-income {
  font-weight: bold;
  display: flex;
}
.revenue-share__row .expected-income .expected-details {
  font-weight: 400;
  margin-left: 12px;
  cursor: pointer;
  text-decoration: underline;
}
.revenue-share__row .expected-income .expected-details:hover {
  font-weight: bold;
}
.revenue-share__row .expected-amount {
  margin-top: 3px;
}
.revenue-share__row .revenue-share-history {
  margin-top: 30px;
}
.revenue-share__row .revenue-share-history-title {
  font-weight: bold;
}
.revenue-share__row .rs-table {
  margin-top: 10px;
  width: 720px;
  border-radius: 2px;
}
.revenue-share__row .rs-table .rs-table-legend {
  display: flex;
  align-items: center;
  width: 720px;
  height: 30px;
  border-bottom: 2px solid #333;
}
.revenue-share__row .rs-table .rs-table-legend .legend {
  border-right: 1px solid #eee;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.revenue-share__row .rs-table .rs-table-legend .legend:last-child {
  border-right: none;
}
.revenue-share__row .rs-table .year-month {
  width: 300px;
}
.revenue-share__row .rs-table .amount {
  width: 200px;
}
.revenue-share__row .rs-table .details {
  width: 200px;
  font-weight: 400;
  margin-left: 12px;
  cursor: pointer;
  text-decoration: underline;
}
.revenue-share__row .rs-table .details:hover {
  font-weight: bold;
}
.revenue-share__row .rs-table .rs-table-item {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #ccc;
}
.revenue-share__row .rs-table .rs-table-item.no-item {
  justify-content: center;
}
.revenue-share__row .rs-table .rs-table-item.no-item .no-content {
  color: #666;
}
.revenue-share__row .rs-table .rs-table-item div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.revenue-share-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.3;
}

.revenue-share-details-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.revenue-share-container {
  width: 768px;
  height: 800px;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  position: relative;
  padding: 24px;
}
.revenue-share-container .close {
  position: absolute;
  top: 24px;
  right: 24px;
  text-decoration: underline;
  cursor: pointer;
}
.revenue-share-container .close:hover {
  font-weight: bold;
}
.revenue-share-container .title {
  font-size: 18px;
  font-weight: bold;
}
.revenue-share-container .total-income {
  margin-top: 6px;
}
.revenue-share-container .expected-income-details {
  margin-top: 30px;
}
.revenue-share-container .expected-income-details-title {
  font-weight: bold;
}
.revenue-share-container .expected-income-table {
  margin-top: 10px;
  width: 720px;
  border-radius: 2px;
}
.revenue-share-container .expected-income-table .expected-income-table-legend {
  display: flex;
  align-items: center;
  width: 720px;
  height: 30px;
  border-bottom: 2px solid #333;
}
.revenue-share-container .expected-income-table .expected-income-table-legend .legend {
  border-right: 1px solid #333;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.revenue-share-container .expected-income-table .expected-income-table-legend .legend:last-child {
  border-right: none;
}
.revenue-share-container .expected-income-table .name {
  width: 300px;
}
.revenue-share-container .expected-income-table .role {
  width: 200px;
}
.revenue-share-container .expected-income-table .share-amount {
  width: 200px;
}
.revenue-share-container .expected-income-table .expected-income-table-content {
  height: 628px;
  overflow: auto;
  border: 2px solid #000;
  border-top: none;
}
.revenue-share-container .expected-income-table .expected-income-table-item {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #ccc;
}
.revenue-share-container .expected-income-table .expected-income-table-item.no-item {
  justify-content: center;
}
.revenue-share-container .expected-income-table .expected-income-table-item div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.revenue-share-container .expected-income-table .expected-income-table-item .role-content {
  font-size: 12px;
  margin-right: 4px;
}
.revenue-share-container .expected-income-table .expected-income-table-item .writer {
  width: 30px;
  height: 20px;
  border-radius: 4px;
  background-color: #e7717d;
  color: #fff;
  font-weight: bold;
}
.revenue-share-container .expected-income-table .expected-income-table-item .narrator {
  width: 50px;
  height: 20px;
  border-radius: 4px;
  background-color: #5bc0eb;
  color: #fff;
  font-weight: bold;
}
.revenue-share-container .expected-income-table .expected-income-table-item .producer {
  width: 50px;
  height: 20px;
  border-radius: 4px;
  background-color: #9368b7;
  color: #fff;
  font-weight: bold;
}

.payment-request-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.3;
}

.payment-request-input-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.payment-request-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.payment-request-content .message {
  margin-top: 50px;
  width: 320px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.payment-request-container {
  width: 400px;
  height: 256px;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  position: relative;
  padding: 24px;
}
.payment-request-container .title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.payment-request-container .input-wrapper {
  margin-top: 32px;
  /* Firefox */
}
.payment-request-container .input-wrapper .amount-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  margin-left: auto;
  margin-right: auto;
}
.payment-request-container .input-wrapper input {
  border: 1px solid #ccc;
  border-right: none;
  font-size: 14px;
  padding: 6px;
  width: 300px;
  text-align: right;
}
.payment-request-container .input-wrapper .won {
  width: 20px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-left: none;
  text-align: right;
}
.payment-request-container .input-wrapper input::-webkit-outer-spin-button,
.payment-request-container .input-wrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.payment-request-container .input-wrapper input[type=number] {
  -moz-appearance: textfield;
}
.payment-request-container .input-wrapper .information {
  margin-top: 6px;
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  font-size: 12px;
  text-align: right;
  color: #333;
}
.payment-request-container .btns-wrapper {
  margin-top: 30px;
}
.payment-request-container .btns-wrapper .submit {
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  background-color: #e7717d;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
}
.payment-request-container .btns-wrapper .submit:hover {
  background-color: #c7747f;
}
.payment-request-container .btns-wrapper .submit.disabled {
  background-color: #666;
  cursor: not-allowed;
}
.payment-request-container .btns-wrapper .cancel {
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  padding: 8px;
  text-align: center;
  border-radius: 6px;
  background-color: #bebebe;
}
.payment-request-container .btns-wrapper .cancel:hover {
  background-color: #aaa;
}

.payment-history {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-left: 40px;
}
.payment-history h1 {
  font-size: 20px;
  font-weight: bold;
}

.ph-table {
  margin-top: 10px;
  width: 960px;
  border-radius: 2px;
}
.ph-table .ph-table-legend {
  display: flex;
  align-items: center;
  width: 960px;
  height: 30px;
  border-bottom: 2px solid #333;
}
.ph-table .ph-table-legend .legend {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ph-table .ph-table-legend .legend:last-child {
  border-right: none;
}
.ph-table .date {
  width: 300px;
}
.ph-table .amount {
  width: 200px;
}
.ph-table .status {
  width: 200px;
}
.ph-table .processed-at {
  width: 260px;
}
.ph-table .ph-table-item {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #ccc;
}
.ph-table .ph-table-item.no-item {
  justify-content: center;
}
.ph-table .ph-table-item div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.ph-table .ph-table-item .status-content {
  font-size: 12px;
  margin-right: 4px;
}
.ph-table .ph-table-item .paid {
  width: 30px;
  height: 20px;
  border-radius: 4px;
  background-color: #e7717d;
  color: #fff;
  font-weight: bold;
}
.ph-table .ph-table-item .pending {
  width: 50px;
  height: 20px;
  border-radius: 4px;
  background-color: #5bc0eb;
  color: #fff;
  font-weight: bold;
}
.ph-table .ph-table-item .canceled {
  width: 50px;
  height: 20px;
  border-radius: 4px;
  background-color: #9368b7;
  color: #fff;
  font-weight: bold;
}
.ph-table .ph-table-item .paid-at.cancel {
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}
.ph-table .ph-table-item .paid-at.cancel:hover {
  font-weight: bold;
}

.ph-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.3;
}

.ph-popup-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.ph-popup-container {
  width: 400px;
  height: 256px;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  position: relative;
  padding: 60px 24px;
}
.ph-popup-container .title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.ph-popup-container .btns-wrapper {
  margin-top: 30px;
}
.ph-popup-container .btns-wrapper .submit {
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  background-color: #e7717d;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
}
.ph-popup-container .btns-wrapper .submit:hover {
  background-color: #c7747f;
}
.ph-popup-container .btns-wrapper .submit.disabled {
  background-color: #666;
  cursor: not-allowed;
}
.ph-popup-container .btns-wrapper .cancel {
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  padding: 8px;
  text-align: center;
  border-radius: 6px;
  background-color: #bebebe;
}
.ph-popup-container .btns-wrapper .cancel:hover {
  background-color: #aaa;
}
